<template>
  <div>
    <!-- Navbar -->
    <div class="row" style="margin-top: 50px">
      <div class="col-sm-6 col-md-3 col-6">
        <!-- Sidebar -->
        <nav
          id="sidebarMenu"
          class="collapse d-lg-block sidebar collapse bg-white pt-5"
        >
          <div class="position-sticky">
            <KTBrand></KTBrand>
            <b-button
              variant="link"
              class="my-1 text-center"
              @click="goToSimulationLink"
              block
              >{{ $t("virtual_pos_api_doc.simulation_link") }}</b-button
            >
            <div class="list-group list-group-flush mx-3 mt-4">
              <button
                v-for="(tab, tabIdx) in miracleCustomerSideTabs"
                :key="`miracleCustomerSideTab-${tabIdx}`"
                @click="tab.onClick"
                class="list-group-item list-group-item-action py-2 ripple"
                aria-current="true"
              >
                <i :class="tab.icon"></i>
                <span class="ml-2">{{ tab.label }}</span>
              </button>
            </div>
          </div>
        </nav>
        <!-- Sidebar -->
      </div>
      <div class="col-sm-6 col-md-9 col-6">
        <div
          class="d-flex py-1 px-1 flex-row mb-3 fixed-top bg-white rounded offset-sm-6 offset-md-3 offset-6"
        >
          <div class="flex-column d-flex">
            <label>{{ $t("virtual_pos_api_doc.customer") }}</label>
            <b-dropdown
              size="sm"
              :text="getCustomerDropdownText()"
              variant="outline-primary"
            >
              <b-dropdown-item-button
                @click="isMiracleCustomer = true"
                :key="'MIRACLE_CUSTOMER'"
              >
                <span class="d-inline-flex align-items-center">
                  <b-icon
                    v-if="isMiracleCustomer"
                    icon="check"
                    font-scale="1.5"
                    class="mr-1"
                    variant="success"
                  ></b-icon>
                  {{
                    $t(
                      "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.MIRACLE_CUSTOMER"
                    )
                  }}
                </span>
              </b-dropdown-item-button>
              <b-dropdown-item-button
                @click="isMiracleCustomer = false"
                :key="'NOT_MIRACLE_CUSTOMER'"
              >
                <span class="d-inline-flex align-items-center">
                  <b-icon
                    v-if="!isMiracleCustomer"
                    icon="check"
                    font-scale="1.5"
                    class="mr-1"
                    variant="success"
                  ></b-icon>
                  {{
                    $t(
                      "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.NOT_MIRACLE_CUSTOMER"
                    )
                  }}
                </span>
              </b-dropdown-item-button>
            </b-dropdown>
          </div>
          <div class="flex-column d-flex ml-2">
            <label>Environment</label>
            <b-dropdown
              size="sm"
              :text="getEnvDropdownText()"
              variant="outline-primary"
            >
              <b-dropdown-item-button @click="onChangeEnv(true)" :key="'dev'">
                <span class="d-inline-flex align-items-center">
                  <b-icon
                    v-if="isDev"
                    icon="check"
                    font-scale="1.5"
                    class="mr-1"
                    variant="success"
                  ></b-icon>
                  Development
                </span>
              </b-dropdown-item-button>
              <b-dropdown-item-button @click="onChangeEnv(false)" :key="'prod'">
                <span class="d-inline-flex align-items-center">
                  <b-icon
                    v-if="!isDev"
                    icon="check"
                    font-scale="1.5"
                    class="mr-1"
                    variant="success"
                  ></b-icon>
                  Production
                </span>
              </b-dropdown-item-button>
            </b-dropdown>
          </div>
        </div>
        <div class="bg-secondary p-10" id="intro">
          <h1>{{ $t("virtual_pos_api_doc.intro") }}</h1>
          <p
            v-html="$t('virtual_pos_api_doc.intro_desc')"
            v-if="isMiracleCustomer"
          ></p>
          <p
            v-html="$t('virtual_pos_api_doc.intro_non_miracle_customer_desc')"
            v-else
          ></p>
          <div class="mt-20">
            <h3>{{ $t("virtual_pos_api_doc.example") }}:</h3>
            <div class="row">
              <img :src="res1" alt="res1" class="w-100 h-75 mb-3" />
            </div>
            <div class="row">
              <img :src="res2" alt="res2" class="w-100 h-75 mb-3" />
            </div>
            <div class="row">
              <img :src="res3" alt="res3" class="w-100 h-75 mb-3" />
            </div>
            <div class="row" v-if="isMiracleCustomer">
              <img :src="res4" alt="res4" class="w-100 h-75 mb-3" />
            </div>
            <div class="row" v-if="!isMiracleCustomer">
              <img :src="res5" alt="res5" class="w-100 h-75 mb-3" />
            </div>
            <div class="row" v-if="!isMiracleCustomer">
              <img :src="res6" alt="res6" class="w-100 h-75" />
            </div>
          </div>
        </div>
        <div class="row p-5">
          <div class="col-sm-12  mt-40 bg-secondary p-10" id="auth">
            <div class="col-sm-12"><h1>Authentication</h1></div>
            <div class="row">
              <div class="col-12">
                <h3>
                  <small
                    >POST-- {{ $t("virtual_pos_api_doc.auth_desc") }}:
                  </small>
                  {{ `${mpayBaseUrl}authentication_token` }}
                </h3>
              </div>
              <pre class="col-12 code text-white">
                Request Body
                {{
                  `
                  {
                    "email": "test@test.com",
                    "password": "000000"
                  }
                  `
                }}
              </pre>
              <pre class="col-12 code mt-2 text-white">
                Response
                {{
                  `
                  {
                    "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9...."
                  }
                  `
                }}
              </pre>
            </div>
          </div>
          <div class="col-sm-12  mt-40 bg-secondary p-10" id="createOrder">
            <div class="col-sm-12">
              <h1>{{ $t("virtual_pos_api_doc.create_order") }}</h1>
            </div>
            <div>
              <div class="col-12">
                <h3>
                  <small
                    >POST-- {{ $t("virtual_pos_api_doc.create_order_desc") }}:
                  </small>
                  {{ `${mpayApiBaseUrl}company-orders` }}
                </h3>
              </div>
              <pre class="col-12 code text-white">
                Request Body
                {{
                  `
                  {
                    "customerMail":"",
                    "customerName":"",
                    "customerPhone":"",
                    "price":"22"
                  }
                  `
                }}
              </pre>
              <pre class="col-12 code mt-2 text-white">
                Response
                {{
                  `
                  {
                    amount: null,
                    createdAt: "2022-07-12T11:34:27+00:00"
                    customerMail: ""
                    customerName: "",
                    customerPhone: "",
                    fee: "5.00",
                    feeIncluded: false,
                    id: 1160,
                    merchantId: 3,
                    nexusAmount: null,
                    orderNumber: "20220712C740386C5FB5",
                    orderStatus: "pending",
                    paymentUnit: null,
                    paymentUnitName: null,
                    platform: null,
                    price: "22.00",
                    selectedCryptoCurrencyBuying: null,
                    selectedCurrencyBuying: null,
                    status: 0,
                    updatedAt:
                    "2022-07-12T11:34:27+00:00"
                  }
                  `
                }}
              </pre>
            </div>
          </div>
          <div class="col-sm-12  mt-40 bg-secondary p-10" id="getCompanyOrder">
            <div class="col-sm-12">
              <h1>{{ $t("virtual_pos_api_doc.get_company_order") }}</h1>
            </div>
            <div>
              <div class="col-12">
                <h3>
                  <small
                    >GET--
                    {{ $t("virtual_pos_api_doc.get_company_order_desc") }}:
                  </small>
                  {{ `${mpayApiBaseUrl}company-orders/{orderId}` }}
                </h3>
              </div>
              <pre class="col-12 code mt-2 text-white">
                Response
                {{
                  `
                  {
                    "id": 392,
                    "selectedCurrencyId": 6,
                    "selectedCurrencyCode": "ETH",
                    "price": "13.00",
                    "fee": "4.00",
                    "feeIncluded": false,
                    "customerName": "",
                    "customerMail": "",
                    "customerPhone": "",
                    "status": 0,
                    "orderNumber": "2022120527FBBA550881",
                    "amount": 0.01021572,
                    "platform": "Nexus",
                    "nexusAmount": null,
                    "paymentUnit": {
                        "id": 1,
                        "name": "USD"
                    },
                    "selectedCurrencyBuying": null,
                    "selectedCryptoCurrencyBuying": null,
                    "nexusAmountConvertCurrency": null,
                    "paymentState": "/api/payment-states/1",
                    "feeAmount": 0.52,
                    "orderTotal": 13.52,
                    "createdAt": "2022-12-05T13:32:14+03:00",
                    "updatedAt": "2022-12-05T13:45:57+03:00",
                    "orderStatus": "pending",
                    "branchTitle": "Test Branch ",
                    "merchantId": 136,
                    "merchantName": "Test Merchant A.Ş.",
                    "paymentUnitName": "USD",
                    "paymentStateId": 1,
                    "paymentStateName": "onstandby"
                  }
                  `
                }}
              </pre>
            </div>
          </div>
          <div class="col-sm-12  mt-40 bg-secondary p-10" id="convert">
            <div class="col-sm-12">
              <h1>{{ $t("virtual_pos_api_doc.convert") }}</h1>
            </div>
            <div>
              <div class="col-12">
                <h3>
                  <small
                    >POST-- {{ $t("virtual_pos_api_doc.convert_desc") }}:
                  </small>
                  {{ `${ofinansApiBaseUrl}miracle-pay/convert` }}
                </h3>
              </div>
              <pre class="col-12 code text-white">
                Request Body
                {{
                  `
                  {
                    "currency":"usd",
                    "crypto":"eth",
                    "amount":100
                  }
                  `
                }}
              </pre>
              <pre class="col-12 code mt-2 text-white">
                Response
                {{
                  `
                  {
                    "success": "ok",
                    "crypto_amount": 0.01435609,
                    "currency": "USD",
                    "crypto_code": "ETH",
                    "message": "your transaction was successfully"
                  }
                  `
                }}
              </pre>
            </div>
          </div>
          <div class="col-sm-12  mt-40 bg-secondary p-10" id="payment_stage">
            <div class="col-sm-12">
              <h1>{{ $t("virtual_pos_api_doc.payment_stage") }}</h1>
            </div>
            <div>
              <div class="col-12 text-break">
                <h3>
                  <small
                    >{{ isMiracleCustomer ? "POST" : "GET" }}--
                    {{ $t("virtual_pos_api_doc.payment_stage_desc") }}:</small
                  >
                  {{
                    isMiracleCustomer
                      ? `${mpayApiBaseUrl}company-orders/{orderId}/transactions/qr-sale`
                      : `${mpayApiBaseUrl}nexus/create_payment/{crypto}/{orderId}/{paymentUnit}`
                  }}
                  <template v-if="!isMiracleCustomer">
                    <p class="mt-2">crypto: <strong>btc,eth</strong>,...</p>
                    <p>paymentUnit: <strong>usdt,try,eur</strong>,...</p>
                  </template>
                </h3>
                <p v-html="$t('virtual_pos_api_doc.payment_stage_desc_2')"></p>
              </div>
              <pre class="col-12 text-white code" v-if="isMiracleCustomer">
                Request Body
                {{
                  `
                  {
                    "hash":"a35ed7fc45142b68b2d46a4f39916...",
                    "selectedCurrencyCode":"ETH",
                    "selectedCurrencyId":2,
                    "selectedPaymentName":"usd"
                  }
                  `
                }}
              </pre>
              <pre class="col-12 text-white code mt-2" v-if="isMiracleCustomer">
                Response
                {{
                  `
                    {
                        "status": 200,
                        "content": {
                            "success": "ok",
                            "transaction_id": 293,
                            "cyrpto_amount": 0.00066117,
                            "currency": "USD",
                            "cyrpto": "BTC",
                            "message": "your transaction was successfully",
                            "user": {
                                "id": 123456,
                                "affiliate_id": null,
                                "name": "User",
                                ..
                            }
                        }
                    }
                    `
                }}
              </pre>
              <pre class="col-12 text-white code mt-2" v-else>
                Response
                {{
                  `
                  {
                      "address": "0x4F1EE081cf92699b011eA7E0583395671f46e4f8",
                      "walletNetwork": {
                        "id": 136,
                        "name": "Ethereum",
                        "code": "ETH",
                        "currency_id": 6,
                        "asset_id": 12,
                        "created_at": "2023-05-07T14:32:09.000000Z",
                        "updated_at": "2023-05-07T14:32:09.000000Z"
                      }
                  }
                  `
                }}
              </pre>
            </div>
          </div>
          <div class="col-sm-12  mt-40 bg-secondary p-10" id="currency">
            <div class="col-sm-12">
              <h1>{{ $t("virtual_pos_api_doc.crypto_currencies") }}</h1>
            </div>
            <div>
              <div class="col-12">
                <h3>
                  <small
                    >GET--
                    {{
                      $t("virtual_pos_api_doc.crypto_currencies_desc")
                    }}:</small
                  >
                  {{ `${mpayApiBaseUrl}companies/{id}/company-wallets` }}
                </h3>
                <p
                  v-html="
                    $t('virtual_pos_api_doc.crypto_currencies_desc_2', {
                      uri: mpayApiBaseUrl
                    })
                  "
                ></p>
              </div>
              <pre class="text-white col-12 code">
                Response
                {{
                  `
                    [
                      {
                        "id": 1111,
                        "balance": "0.00",
                        "wallet": {
                            "currencyCode": "BTC",
                            "currencyName": "Bitcoin",
                            "currencyIcon": "cdnpath/btc.png",
                            "createdAt": "2024-01-21T09:07:47+00:00",
                            "updatedAt": "2024-01-21T09:07:47+00:00"
                        },
                        "createdAt": "2024-02-12T13:36:56+00:00",
                        "updatedAt": "2024-02-12T13:36:56+00:00"
                      },
                      {
                        "id": 1112,
                        "balance": "0.00",
                        "wallet": {
                            "currencyCode": "ETH",
                            "currencyName": "Ethereum",
                            "currencyIcon": "cdnpath/eth.png",
                            "createdAt": "2024-01-21T09:07:47+00:00",
                            "updatedAt": "2024-01-21T09:07:47+00:00"
                        },
                        "createdAt": "2024-02-12T13:36:56+00:00",
                        "updatedAt": "2024-02-12T13:36:56+00:00"
                      },
                      ...
                    ]
                  `
                }}
              </pre>
            </div>
          </div>
          <div class="col-sm-12 mt-40 bg-secondary p-10" id="getMe">
            <div class="col-sm-12">
              <h1>{{ $t("virtual_pos_api_doc.getMe") }}</h1>
            </div>
            <div>
              <div class="col-12">
                <h3>
                  <small
                    >GET-- {{ $t("virtual_pos_api_doc.getMe_desc") }}:</small
                  >
                  {{ `${mpayApiBaseUrl}me` }}
                </h3>
              </div>
              <pre class="text-white code col-12">
                Response
                {{
                  `
                  {
                    "parent": {
                        "id": 5,
                        "title": "Test Company Ltd.",
                        "email": "other@branch.com",
                        "taxCity": "adsdasd",
                        "taxAdministration": "dasdasd",
                        "taxNumber": "32123",
                        "hasQuickSale": false,
                        "role": {
                            "roleRank": "ROLE_BRANCH",
                            "canSubDeal": false,
                            "hasOfinansAccount": false,
                            "hasWallets": false,
                            "canSellQrCode": true
                        },
                        "isActive": true,
                        "paymentUnit": {
                            "id": 1,
                            "name": "usd"
                        },
                        "commission": 5,
                        "deletedAt": null,
                        "createdAt": "2022-01-03T10:08:50+03:00",
                        "updatedAt": "2022-07-06T20:55:19+03:00",
                        "companyRank": "BRANCH",
                        "ordersTotals": "108,422.72"
                    },
                    "posNumber": null,
                    "createdAt": "2022-01-03T10:08:50+03:00",
                    "updatedAt": "2022-09-15T15:15:58+03:00",
                    "id": 7,
                    "roles": [
                        "ROLE_BRANCH"
                    ],
                    "email": "other@branch.com",
                    "phone": "+905",
                    "firstName": "Other",
                    "lastName": "Admin",
                    "isActive": true,
                    "userRole": {
                        "roleRank": "ROLE_BRANCH",
                        "canSubDeal": false,
                        "hasOfinansAccount": false,
                        "hasWallets": false,
                        "canSellQrCode": true
                    },
                    "googleTfaEnabled": false,
                    "merchantId": 3,
                    "fullName": "Other Admin"
                  }
                  `
                }}
              </pre>
            </div>
          </div>
          <div class="col-sm-12 mt-40 bg-secondary p-10" id="deleteAddress">
            <div class="col-sm-12">
              <h1>{{ $t("virtual_pos_api_doc.delete_wallet_address") }}</h1>
            </div>
            <div>
              <div class="col-12">
                <h3>
                  <small
                    >POST--
                    {{ $t("virtual_pos_api_doc.delete_wallet_address_desc") }}:
                  </small>
                  {{ `${mpayApiBaseUrl}delete-wallet-address` }}
                </h3>
              </div>
              <pre class="col-12 code text-white">
                Request Body
                {{
                  `
                  {
                    "address": "0x4F1............",
                  }
                  `
                }}
              </pre>
              <pre class="col-12 code mt-2 text-white">
                Response
                {{
                  `
                  {
                    "success": "ok",
                    "message": "success"
                  }
                  `
                }}
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import KTBrand from "@/view/layout/brand/Brand.vue";
import { OVERRIDE_PAGE_LAYOUT_CONFIG } from "../../store/modules/config";
export default {
  components: {
    KTBrand
  },
  computed: {
    res1() {
      return require("@/assets/images/doc-images/res-1.png");
    },
    res2() {
      return require("@/assets/images/doc-images/res-2.png");
    },
    res3() {
      return require("@/assets/images/doc-images/res-3.png");
    },
    res4() {
      return require("@/assets/images/doc-images/res-4.png");
    },
    res5() {
      return require("@/assets/images/doc-images/res-5.png");
    },
    res6() {
      return require("@/assets/images/doc-images/res-6.png");
    }
  },
  data() {
    return {
      isMiracleCustomer: true,
      miracleCustomerSideTabs: [
        {
          label: this.$t("virtual_pos_api_doc.intro"),
          onClick: () => {
            this.scrollToView("#intro");
          },
          icon: "fas fa-tachometer-alt fa-fw me-3"
        },
        {
          label: "Authentication",
          onClick: () => {
            this.scrollToView("#auth");
          },
          icon: "fas fa-chart-area fa-fw me-3"
        },
        {
          label: this.$t("virtual_pos_api_doc.create_order"),
          onClick: () => {
            this.scrollToView("#createOrder");
          },
          icon: "fas fa-lock fa-fw me-3"
        },
        {
          label: this.$t("virtual_pos_api_doc.get_company_order"),
          onClick: () => {
            this.scrollToView("#getCompanyOrder");
          },
          icon: "fas fa-info fa-fw me-3"
        },
        {
          label: this.$t("virtual_pos_api_doc.convert"),
          onClick: () => {
            this.scrollToView("#convert");
          },
          icon: "fas fa-exchange-alt fa-fw me-3"
        },
        {
          label: this.$t("virtual_pos_api_doc.payment_stage"),
          onClick: () => {
            this.scrollToView("#payment_stage");
          },
          icon: "fas fa-chart-line fa-fw me-3"
        },
        {
          label: this.$t("virtual_pos_api_doc.crypto_currencies"),
          onClick: () => {
            this.scrollToView("#currency");
          },
          icon: "fas fa-coins fa-fw me-3"
        },
        {
          label: this.$t("virtual_pos_api_doc.getMe"),
          onClick: () => {
            this.scrollToView("#getMe");
          },
          icon: "fas fa-user fa-fw me-3"
        },
        {
          label: this.$t("virtual_pos_api_doc.delete_wallet_address"),
          onClick: () => {
            this.scrollToView("#deleteAddress");
          },
          icon: "fas fa-trash fa-fw me-3"
        }
      ],
      isDev: true,
      mpayBaseUrl: "https://apidev.miraclepay.nl/",
      mpayApiBaseUrl: "https://apidev.miraclepay.nl/api/",
      ofinansApiBaseUrl: "https://apidev.miraclecash.nl/v2/"
    };
  },
  methods: {
    scrollToView(idOrClass) {
      document.querySelector(idOrClass).scrollIntoView({ behavior: "smooth" });
    },
    getCustomerDropdownText() {
      return this.isMiracleCustomer
        ? this.$t(
            "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.MIRACLE_CUSTOMER"
          )
        : this.$t(
            "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.NOT_MIRACLE_CUSTOMER"
          );
    },
    getEnvDropdownText() {
      return this.isDev ? "Development" : "Production";
    },
    onChangeEnv(isDev) {
      this.isDev = isDev;
      if (isDev) {
        this.mpayBaseUrl = "https://apidev.miraclepay.nl/";
        this.mpayApiBaseUrl = "https://apidev.miraclepay.nl/api/";
        this.ofinansApiBaseUrl = "https://apidev.miraclecash.nl/v2/";
        return;
      }
      this.mpayBaseUrl = "https://api.miraclepay.nl/";
      this.mpayApiBaseUrl = "https://api.miraclepay.nl/api/";
      this.ofinansApiBaseUrl = "https://api.miraclecash.nl/v2/";
    },
    goToSimulationLink() {
      window.open(process.env.VUE_APP_VIRTUAL_POS_SIMULATION_APP, "_blank");
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Empty Page" }]);
    this.$store.dispatch(OVERRIDE_PAGE_LAYOUT_CONFIG, {
      aside: { self: { minimize: { toggle: false } } }
    });
  }
};
</script>
<style>
body {
  background-color: #fbfbfb;
}

@media (min-width: 991.98px) {
  main {
    padding-left: 240px;
  }
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 58px 0 0; /* Height of navbar */
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
  width: 240px;
  z-index: 600;
}

@media (max-width: 991.98px) {
  .sidebar {
    width: 100%;
  }
}

.sidebar .active {
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.code {
  background-color: #3f4254;
  color: white;
  padding: 20px;
}
</style>
